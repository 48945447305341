import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Modal, Form } from "react-bootstrap";
import { AccountContext } from "../Contexts/AccountContext";
import { AssmtContext } from "../Contexts/AssmtContext";
import AccessDenied from "../AccessDenied";
import Navigate from "../Navigate";
import ToastMessage from "../ToastMessage";
import { CSVLink } from "react-csv";
import { SiAddthis } from "react-icons/si";
import { FaFolderOpen, FaDatabase } from "react-icons/fa";
import { CheckboxDropdown } from "../CheckboxDropdown";
import "moment-timezone";
import { Helmet } from "react-helmet";
import "datatables.net-dt/css/jquery.dataTables.css";
import $ from "jquery";


const Admin = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const {
    assmt,
    toast,
    setToast,
    message,
    status,
    setPublishedFilter,
    isLoadingAssmt,
    setGroupFilter,
    yearsFilter,
    setYearsFilter,
    setAssmtType,
  } = useContext(AssmtContext);

  const [masterExport, setMasterExport] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  const handleOnClickView = (id, type) => {
    setAssmtType(type);
    if (type === "individual") {
      navigate(`/admin/viewAssmt/${id}`);
      localStorage.setItem("AGID", undefined);
    } else if (type === "group") {
      navigate(`/admin/viewGrpAssmt/${id}`);
      localStorage.setItem("AGID", id);
    }
  };

  const generateMasterExport = async () => {
    const { data } = await apiCall("admin/masterExport");
    setMasterExport(data);
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      scrollX: true,
      order: [[6,'desc',[0,'asc']]],
      columnDefs: [
        {
          target: 0,
          visible: false,
          searchable: true
        },
        {
          target: 1,
          visible: false,
          searchable: false
        },
        {
          target: 2,
          searchable: false,
          // width: '30%'
        },
        // {
        //   target: 6,
        //   createdCell:  function (td, cellData, rowData, row, col) {
        //     console.log("cd : ",cellData);
        //     console.log("td : ",td);
        //    td.setAttribute('data-sort',new Date(cellData.split('T')[0]).getTime());
        // }
        // }
      ],
      data: assmt,
      columns: [
        {
          className: "dt-body-left",
          title: "Assessment Name",
          data: "name",
        },
        {
          className: "dt-body-left",
          title: "Description",
          data: "description",
        },
        {
          className: "dt-body-left",
          title: "Assessment",
          data: null,
          render: function(data, type, row){
             return (`
              <div class="admin_assessment_col">
                <p style="font-size: 16px;"><b>${row.name[0].toUpperCase() + row.name.slice(1)}</b></p>
                <p>${row.description}</p>
              </div>
              `
             )
          }
        },
        {
          className: "dt-body-center",
          title: "Published",
          data: "published",
        },
        {
          className: "dt-body-center",
          title: "Responses",
          data: "count",
        },
        {
          className: "dt-body-center",
          title: "Created On",
          data: "TOC",
          render: function (data) {
            let date = data.split(" ")[0];
            return date;
          },
        },
        {
          className: "dt-body-center",
          title: "Updated On",
          data: "updatedAt",
          render: function (data) {
            let date = data.split("T")[0];
            return date;
          },
        },
        {
          className: "dt-body-center",
          title: "Last Modified By",
          data: "modified_by",
        },
        {
          className: "dt-body-center",
          title: "Visibility",
          data: "isPublic",
          render: function (data) {
            if (data === 0) {
              return "Restricted";
            } else {
              return "Public";
            }
          },
        },
      ],
      // createdRow: function(row, data, dataIndex){
      //     // console.log("tr : ", row.childNodes());
      //     let tdNode = this.api().row(row).nodes();
      //     console.log(tdNode);
      //     // setAttribute('data-order',data.updatedAt.split("T")[0]);
      // },
      destroy: true,
    });

    $("#example2 tbody").on("click", 'tr', function () {
      const data = table.row($(this)).data();
      if (data !== undefined) {
        handleOnClickView(data.AID, data.type);
      }
    });

    return function () {
      table.destroy();
    };
  }, [assmt]);

  return (
    <div>
      <Helmet>
        <title>Helix Employability Tool | Admin Dashboard</title>
      </Helmet>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Navigate />
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Helix</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Master export for all user response for all published assessent is
              genereted as .csv file and ready to download!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <CSVLink
                data={masterExport}
                headers={[
                  { label: "Assessment ID", key: "AID" },
                  { label: "User ID", key: "UID" },
                  { label: "User Assmt ID", key: "UAID" },
                  { label: "Section Name", key: "sec_name" },
                  { label: "Question", key: "question" },
                  { label: "Type", key: "type" },
                  { label: "Choice picked", key: "choice" },
                  { label: "Weightage of choice", key: "weightage" },
                  { label: "Weightage of response", key: "response" },
                ]}
                filename={`Helix Master Export (exported on ${new Date().toLocaleDateString()})`}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <Stack direction="horizontal" gap={3}>
            <label>Assessment List:</label>
            <div className="ms-auto">
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => navigate("/admin/questionBank")}
              >
                <FaFolderOpen style={{ color: "#163172", fontSize: "20px" }} />{" "}
                Question Bank
              </Button>{" "}
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => {
                  generateMasterExport();
                  handleShow();
                }}
              >
                {" "}
                <FaDatabase
                  style={{ color: "#163172", fontSize: "20px" }}
                />{" "}
                Master Export
              </Button>{" "}
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => {
                  navigate("/admin/newAssmt");
                }}
              >
                {" "}
                <SiAddthis style={{ color: "#163172", fontSize: "20px" }} /> New
                Assessment
              </Button>
              <Button
                variant="outline-light"
                style={{ color: "#163172" }}
                onClick={() => {
                  navigate("/admin/newAssmtGroup");
                }}
              >
                {" "}
                <SiAddthis style={{ color: "#163172", fontSize: "20px" }} /> New
                Assessment Group
              </Button>
            </div>
          </Stack>
          <br />
          <Stack direction="horizontal" className="my-2 mb-4" gap={5}>
            <Stack direction="horizontal" gap={2}>
              <p className="m-0">Published:</p>
              <Form.Select
                aria-label="Published Select"
                onChange={(e) => {
                  if (["yes", "no"].includes(e.target.value))
                    setPublishedFilter(e.target.value);
                  else setPublishedFilter(null);
                }}
              >
                <option value="all">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Select>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <p className="m-0">Assessment Type:</p>
              <Form.Select
                aria-label="Published Select"
                onChange={(e) => {
                  if (["group", "individual"].includes(e.target.value))
                    setGroupFilter(e.target.value);
                  else setGroupFilter(null);
                }}
              >
                <option value="all">All</option>
                <option value="group">Group</option>
                <option value="individual">Individual</option>
              </Form.Select>
            </Stack>
            <Stack direction="horizontal" gap={2}>
              <p className="m-0">Year:</p>
              <CheckboxDropdown
                items={Object.keys(yearsFilter) || []}
                onChange={(changeObj) => {
                  setYearsFilter({
                    ...yearsFilter,
                    [changeObj.name]: changeObj.checked,
                  });
                }}
              />
            </Stack>
          </Stack>
          {isLoadingAssmt ? (
            <p>Loading...</p>
          ) : (
            <>
              <div className="user-table">
                <table
                  className="hover dt[-head|-body]-left cell-border"
                  width="100%"
                  id="example2"
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th>Assessment Name</th>
                      <th>Description</th>
                      <th>Assessment</th>
                      <th style={{ textAlign:'center'}}>Published</th>
                      <th style={{ textAlign:'center'}}>Responses</th>
                      <th style={{ textAlign:'center'}}>Created On</th>
                      <th style={{ textAlign:'center'}}>Updated On</th>
                      <th style={{ textAlign:'center'}}>Last Modified By</th>
                      <th style={{ textAlign:'center'}}>Visibility</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
      <br />
      <br />
      <br />
    </div>
  );
};

export default Admin;
