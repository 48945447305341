import React, { useContext, useState, useEffect, useRef } from "react";
import { Form, Stack, Button, Modal } from "react-bootstrap";
import { FaMinus } from "react-icons/fa";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { EditAssmtContext } from "../../Contexts/EditAssmtContext";
import ToastMessage from "../../ToastMessage";
import { AssmtContext } from "../../Contexts/AssmtContext";


const EditChoiceSet = (props) => {
  const { sectionList } = useContext(EditAssmtContext);
  const [tooltipText, setTooltipText] = useState(
    sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx]?.tooltip || ""
  );
  const [tooltipEnabled, setTooltipEnabled] = useState(!!tooltipText);
  const [tooltipWarning, setTooltipWarning] = useState("");
  const [val,setVal] = useState(3);
  const editing = props.editing;
  const textOnly = props.textOnly;
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const [options,setOptions] = useState(props.singleChoice?.options_list?.split(",") || []);
  const [placeholder, setPlaceholder] = useState(props.singleChoice?.placeholder || "");
  const [option,setOption] = useState("");
  const [toast,setToast] = useState(false); //it represents the error if any
  
 
  //function for closing out dropdown modal
  const handleDropdownModal = () => {
    setShowDropdownModal(false);
    setOption("");
    setOptions(props.singleChoice?.options_list?.split(",") || []);
  }

  //function for opening the dropdown modal
  const handleOpenDropdownModal = () => {
    setShowDropdownModal(true);
  }

  const handleTooltipChange = (e) => { 
    let value = e.target.value;
    if (value.length > 250) {
      setTooltipWarning(
        "Tooltip text exceeded 250 characters and has been truncated."
      );
      value = value.slice(0, 250);
    } else {
      setTooltipWarning(""); // Clear the warning if within limit
    }
    setTooltipText(value);

    // Ensure the tooltip change is passed back to EditQuestionSet
    props.handleChoiceChange(
      { target: { name: "tooltip", value } },
      props.cidx
    );
  };

  // Toggle tooltip input visibility based on checkbox
  const handleTooltipCheckboxChange = () => {
    setTooltipEnabled(!tooltipEnabled);
    if (!tooltipEnabled) {
      setTooltipText("");
    }
  };

  const handleOptionChange = (e) => {
    props.handleChoiceChange(
      { target: { name: "opt_type", value: e.target.value } },
      props.cidx
    );
  }

  const addOption = () => {
     setOptions((prevOptions)=> {
      return [...prevOptions, option]
     });

     setOption("");
     return;
  }

  const handleDropdownRequirements = () => {
    //firstly we will verify the inputs
    if(placeholder.trim() === "" || options.length === 0){
      setToast(true);
      return;
    }

    //send the data to the parent component if everything seems good and close the modal
    props.handleChoiceChange(
      { target: { name: "options_list", value: options.join(",") } },
      props.cidx
    );

    props.handleChoiceChange(
      { target: { name: "placeholder", value: placeholder } },
      props.cidx
    );
    
    handleDropdownModal();
  }

  const deleteOption = (optionArg) => {
    setOptions((optArray)=>{
      return optArray.filter((opt)=>{
        return opt !== optionArg;
      })
    })
  }


  return (
    <div className="c-main">
      <Stack direction="horizontal" gap={2}>
        {/* modal for adding dropdown options  */}
        <Modal
            show={showDropdownModal}
            onHide={handleDropdownModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
            {/* error toaster  */}
            <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={"Either the placeholder value is missing or options list is empty!"}
            status={false}
          />
              <Modal.Title>Dropdown Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Add the placeholder text for dropdown and list all of its options.
            <Form.Control
            className="c-text my-1"
            type="text"
            placeholder="Enter your placeholder text"
            name="placeholder"
            value={placeholder}
            onChange={(e) => {
              setPlaceholder(e.target.value)
            }}
          />
          <Stack direction="horizontal"  className="my-1">
          <Form.Control
            className="c-text"
            type="text"
            placeholder="Enter dropdown options"
            name="options"
            value={option}
            onChange={(e) => {
              setOption(e.target.value)
            }}
          />
          <Button variant="outline-success" onClick={addOption} style={{marginLeft: '0.5rem'}} 
          disabled={option.trim() === ""}
          >
                Add
          </Button>
          </Stack>
          <div className="d-flex flex-wrap my-3" style={{maxHeight: '250px', width: '100%',overflowY:'auto'}}>
              {
                options.map((option)=>{
                  return (
                    <>
                     <div className="p-1 m-1 align-self-start border border-secondary rounded option"
                     >
                      <div className="option_child" onClick={()=> deleteOption(option)}>
                        <p className="option_sign">-</p>
                      </div>
                         {option}
                     </div>
                    </>
                  )
                })
              }
          </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleDropdownModal}>
                Cancel
              </Button>
              <Button variant="outline-primary" onClick={handleDropdownRequirements}>
                  Done
                </Button>
            </Modal.Footer>
          </Modal>

        <div style={{ flexGrow: 1, padding:'10px', border:'2px solid rgb(221, 220, 220)', borderRadius:'15px' }}>
          {/* options for adding extra option in the slider related choices  */}
          {/* show only for slider related questions  */}
          {sectionList[props.sidx].qIDs[props.qidx].qType === 'Slider' ? 
           <Stack direction='horizontal'>
           <Form.Check
               checked={props.singleChoice.opt_type == '1'}
               disabled={editing}
               label={"Dropdown"}
               type="radio"
               name={props.cidx}
               value={'1'}
               style={{ marginLeft: "auto" }}
               onChange={handleOptionChange}
             />
           <Form.Check
               checked={props.singleChoice.opt_type == '2'}
               disabled={editing}
               label={"Text"}
               type="radio"
               name={props.cidx}
               value={'2'}
               style={{ marginLeft: "10px" }}
               onChange={handleOptionChange}
             />
             <Form.Check
               checked={props.singleChoice.opt_type == '3'}
               disabled={editing}
               label={"None"}
               type="radio"
               name={props.cidx}
               value={'3'}
               style={{ marginLeft: "10px" }}
               onChange={handleOptionChange}
             />
         </Stack>
          : 
          <></>
          }

          {/* Choice Text Field */}
          <Form.Control
            disabled={editing}
            className="c-text"
            type="text"
            placeholder="Choice"
            name="cName"
            value={
              sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx].cName
            }
            onChange={(e) => {
              props.handleChoiceChange(e, props.cidx);
            }}
          />

          {/* Tooltip Input Field and Tooltip Checkbox */}
          <Stack direction="horizontal" gap={2} style={{ marginTop: "0.5rem" }}>
            {tooltipEnabled && (
              <Form.Control
                disabled={editing}
                className="c-tooltip"
                type="text"
                placeholder="Your Text Here (Max 250 chars)"
                name="tooltip"
                value={tooltipText}
                onChange={handleTooltipChange}
                style={{ flexGrow: 1 }}
              />
            )}
            {/* Tooltip Checkbox - Always on the right */}
            <Form.Check
              type="checkbox"
              name="tooltipEnabled"
              label="Tooltip"
              checked={tooltipEnabled}
              onChange={handleTooltipCheckboxChange}
              disabled={editing}
              style={{ marginLeft: tooltipEnabled ? "10px" : "auto" }}
            />
          </Stack>
          {tooltipWarning && (
            <div
              style={{ color: "red", fontSize: "small", marginTop: "0.5rem" }}
            >
              {tooltipWarning}
            </div>
          )}

          {/* Additional Choice Controls */}
          <Stack
            direction="horizontal"
            gap={2}
            style={{
              marginTop: "0.5rem",
              justifyContent: "flex-end",
            }}
          >
            {/* Weightage Label and Field */}
            <div
              className="c-weightage"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginRight: "8px" }}>Weightage</span>
              <Form.Control
                disabled={editing || textOnly}
                type="number"
                step="0.1"
                placeholder="Weight"
                name="weight"
                value={
                  sectionList[props.sidx].qIDs[props.qidx].cIDs[props.cidx]
                    ?.weight || 0
                }
                onChange={(e) => {
                  props.handleChoiceChange(e, props.cidx);
                }}
                style={{ width: "65px" }}
              />
            </div>
            {
              props.singleChoice.opt_type == 1 ? (
                <>
                <Button variant="outline-primary" className="btn btn-sm" onClick={handleOpenDropdownModal}
                disabled={editing}
                >
                   Add Options
                </Button>
                </>
              ) : props.singleChoice.opt_type == 2 ? (
              <> 
              <Form.Control
              disabled={editing}
              className="c-text"
              type="text"
              placeholder="Enter placeholder text"
              name="placeholder"
              value={
                props.singleChoice.placeholder
              }
              onChange={(e) => {
                console.log("val  : ", e.target.value);
                props.handleChoiceChange(
                  { target: { name: "placeholder", value: e.target.value } },
                  props.cidx
                );
              }}
            />
              </>
          )
              : 
              <></>
            }
          </Stack>
        </div>

        {/* Vertical Divider */}
        <div className="vr" />

        {/* Move Up / Down and Remove Buttons */}
        <Stack direction="horizontal" gap={0}>
          <Button
            disabled={editing || textOnly}
            variant="outline-light"
            onClick={() => props.moveUp(props.cidx)}
          >
            <GoArrowUp style={{ color: "#163172", fontSize: "20px" }} />
          </Button>
          <Button
            disabled={editing || textOnly}
            variant="outline-light"
            onClick={() => props.moveDown(props.cidx)}
          >
            <GoArrowDown style={{ color: "#163172", fontSize: "20px" }} />
          </Button>
        </Stack>
        <div className="vr" />
        <Button
          disabled={editing || textOnly}
          className="sec-x-btn"
          variant="outline-light"
          onClick={() => {
            props.handleChoiceRemove(props.cidx);
          }}
        >
          <FaMinus style={{ color: "#BD4B4B", fontSize: "15px" }} />
        </Button>
      </Stack>
    </div>
  );
};

export default EditChoiceSet;
