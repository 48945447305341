import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Form,
  Card,
  Button,
  Accordion,
  Badge,
  Table,
  Modal,
} from "react-bootstrap";
import { AssmtContext } from "../../Contexts/AssmtContext";
import Navigate from "../../Navigate";
import NFCListSet from "../Assmt/NFCList.js";
import ToastMessage from "../../ToastMessage";
import { FaEye } from "react-icons/fa";
import {
  AiOutlineRadarChart,
  AiOutlineCloseCircle,
  AiTwotoneDelete,
  AiOutlineTeam
} from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { RiSaveFill } from "react-icons/ri";
import {
  TbFileSymlink,
  TbFileCheck,
  TbFileX,
  TbFiles,
  TbFileDownload,
  TbFileSettings,
} from "react-icons/tb";
import { AccountContext } from "../../Contexts/AccountContext";
import AccessDenied from "../../AccessDenied";
import { CSVLink } from "react-csv";
import { FaFolderMinus, FaFolderPlus } from "react-icons/fa";
import PreviewAssmt from "./PreviewAssmt";
import { default as RModal } from "react-modal/lib/components/Modal";
import {
  isNFCComplete,
  validateNFCOverlapHelper,
} from "../../../utils/helpers.js";
import moment from "moment";
import "moment-timezone";
import { Helmet } from "react-helmet";
import ModifyVisibilityModal from "./ModifyVisibility.js";

RModal.setAppElement("#root");

const ViewAssmt = () => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const { toast, setToast, message, setMessage, status, setStatus, setAssmt } =
    useContext(AssmtContext);

  const navigate = useNavigate();

  const AID = useParams().id;

  const [assmtObj, setAssmtObj] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [deletedNFCList, setDeletedNFCList] = useState([]);
  const [analysisBtnDisabled, setAnalysisBtnDisabled] = useState(false);
  const [editNFCRange, setEditNFCRange] = useState(true);
  const [currNFCRangeIdx, setCurrNFCRangeIdx] = useState(0);
  const [prevBool, setPrevBool] = useState(false);
  const [nfcCheckedList, setNfcCheckedList] = useState([]);
  const [hcsCheckedList, setHcsCheckedList] = useState([]);
  const [communicationCheckedList, setCommunicationCheckedList] = useState([]);
  const [excludeCheckedList, setExcludeCheckedList] = useState([]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [weightList, setWeightList] = useState([]);
  const [ analysisTypeList, setAnalysisTypeList] = useState([]);
  const [allUserRes, setAllUserRes] = useState([]);
  const [isOpenNFCModal, setIsOpenNFCModal] = useState(false);
  const [modifyVisibilityModal, setModifyVisibilityModal] = useState(false);
  const [groups,setGroups] = useState([]); //list of groups that has the access of this assessment


  const [communicationChecked, setCommunicationChecked] = useState([]);
  const [sectionGraphObj, setSectionGraphObj] = useState({});
  const [questionLabels, setQuestionLabels] = useState({});
  const [sectionLabels, setSectionLabels] = useState({});

  const [deleteOpr, setDeleteOpr] = useState("");

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const toggleprev = () => {
    setPrevBool(!prevBool);
  };

  const toggleAnalysisBtn = () => {
    setAnalysisBtnDisabled(!analysisBtnDisabled);
  };

  const editNFCRangefunc = () => {
    setEditNFCRange(!editNFCRange);
  };

  const getAssmtdetails = async (AID) => {
    try{
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
  
      const cnt = await apiCall(`admin/getResponseCount/${AID}`);
      
      if(data[0]["isPublic"] == false){
        const response = await apiCall(
          `/admin/getGroupsOfAssessment/${AID}`
        );
        console.log("resppp : ", response);
  
        if (response?.data?.error) {
          throw response.data.error;
        }
        setGroups(response?.data?.data);
      }
  
      data[0]["hasResponse"] = cnt.data > 0 ? true : false;
      setAssmtObj(data[0]);
    }catch(err){
      console.log("Error : ",err);
      setMessage("Sorry, unable to fetch assessment details. Please try again later!");
      setToast(true);
      setStatus(false);
    }
  };

  const getAssmt = async (AID) => {
    const { data } = await apiCall(`admin/getAssmt/${AID}`);
    let sectionList = [];
    const communicationSet = new Set();
    data.forEach(async (sec, sidx, sArray) => {
      let sObj = {};
      sObj["sName"] = sec.sec_name;
      sObj["sDesc"] = sec.sec_desc;
      sObj["sType"] = sec.sec_analysisType;
      sObj["SecID"] = sec.SecID;
      sObj["nfcData"] = sec.nfcData;
      sObj["nfc"] = sec.nfc === 1;
      sObj["sectionGraph"] = sec.sectionGraph === 1;
      sObj["sectionLabel"] = sec.label;
      let qIDs = [];
      if (sec.questions.length === 0) sObj["qIDs"] = qIDs;
      sec.questions.forEach(async (que, qidx, qArray) => {
        let qObj = {};
        qObj["sidx"] = sidx;
        qObj["QID"] = que.QID;
        qObj["qQuestion"] = que.question;
        qObj["qType"] = que.type;
        qObj["inQBank"] = que.inQBank;
        qObj["hcs"] = que.hcs;
        qObj["nfc"] = que.nfc;
        qObj["label"] = que.label;
        qObj["exclude"] = que.exclude;
        qObj["tooltip"] = que.tooltip;
        let cIDs = [];
        if (que.choices.length === 0) qObj["cIDs"] = cIDs;
        que.choices.forEach(async (ch, cidx, cArray) => {
          let cObj = {};
          cObj["CID"] = ch.CID;
          cObj["qidx"] = qidx;
          cObj["sidx"] = sidx;
          cObj["cName"] = ch.choice;
          cObj["weight"] = ch.weightage;
          cObj["communication"] = ch.communication;
          cObj["tooltip"] = ch.tooltip;
          cObj["placeholder"] = ch.placeholder;
          cObj["opt_type"] = ch.opt_type;
          cObj["options_list"] = ch.options_list;
          if (ch.communication === 1) communicationSet.add(ch.QID);
          cIDs.push(cObj);
          if (cidx === cArray.length - 1) {
            qObj["cIDs"] = cIDs;
          }
        });
        qIDs.push(qObj);
        if (qidx === qArray.length - 1) {
          sObj["qIDs"] = qIDs;
        }
      });
      sectionList.push(sObj);
      setCommunicationChecked(Array.from(communicationSet));
      if (sidx === sArray.length - 1) {
        setSectionList(sectionList);
      }
    });
  };

  const exportAllUserRes = async (id) => {
    const { data } = await apiCall(`admin/exportAllAssmtResToCSV/${id}`);
    // console.log(data);

    const formatDate = (dateString) => {
      const dateObj = new Date(dateString);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = dateObj.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const groupedData = data.reduce((acc, curr) => {
      const key = `${curr.UID}-${curr.UAID}`;
      if (!acc[key]) {
        acc[key] = {
          group_name: curr.group_name,
          UID: curr.UID,
          UAID: curr.UAID,
          referenceDate: formatDate(curr.referenceDate),
          sections: [],
        };
      }

      let section = acc[key].sections.find((s) => s.sec_name === curr.sec_name);
      if (!section) {
        section = {
          sec_name: curr.sec_name,
          sec_score: curr.sec_score,
          questions: [],
        };
        acc[key].sections.push(section);
      }

      section.questions.push({
        question: curr.question,
        type: curr.type,
        choicePicked: curr.choice,
        weightageChoice: curr.weightage,
        weightageResponse: curr.response,
      });

      return acc;
    }, {});

    const formattedData = Object.keys(groupedData).map((key) => {
      const userData = groupedData[key];
      const flattened = {
        group_name: userData.group_name,
        UID: userData.UID,
        UAID: userData.UAID,
        referenceDate: userData.referenceDate,
      };

      userData.sections.forEach((section, sectionIdx) => {
        flattened[`Section ${section.sec_name}`] = section.sec_score;
      });

      userData.sections.forEach((section, sectionIdx) => {
        flattened[`Section Name ${sectionIdx + 1}`] = section.sec_name;
        flattened[`Score Obtained ${sectionIdx + 1}`] = section.sec_score;

        section.questions.forEach((questionData, questionIdx) => {
          flattened[`Question ${sectionIdx + 1}-${questionIdx + 1}`] =
            questionData.question;
          flattened[`Type of Question ${sectionIdx + 1}-${questionIdx + 1}`] =
            questionData.type;
          flattened[`Choice Picked ${sectionIdx + 1}-${questionIdx + 1}`] =
            questionData.choicePicked;
          flattened[
            `Weightage of Choice ${sectionIdx + 1}-${questionIdx + 1}`
          ] = questionData.weightageChoice;
          flattened[
            `Weightage of Response ${sectionIdx + 1}-${questionIdx + 1}`
          ] = questionData.weightageResponse;
        });
      });

      return flattened;
    });

    setAllUserRes(formattedData);
  };

  useEffect(() => {
    if (!AID || AID === "" || !loggedInUser.jwtToken) return;
    getAssmtdetails(AID);
  }, [AID, loggedInUser.jwtToken]);

  useEffect(() => {
    if (!AID || AID === "" || !loggedInUser.jwtToken) return;
    getAssmt(AID);
  }, [AID, loggedInUser.jwtToken]);

  const handleOnClickView = (id) => {
    let assmtName = { assmtName: assmtObj.name };
    localStorage.setItem("viewResAssmtName", JSON.stringify(assmtName));
    localStorage.setItem("datatablePage", 0);
    navigate(`/admin/viewAllResponse/${id}`);
  };

  const handleWeightChange = (CID, weightage) => {
    if (weightList.length) {
      let newWeightList = weightList.filter((i) => i.CID !== CID);
      let newWeight = {};
      newWeight["CID"] = CID;
      newWeight["weightage"] = weightage;
      newWeightList.push(newWeight);
      setWeightList(newWeightList);
    } else {
      let newWeight = {
        CID: CID,
        weightage: weightage,
      };
      let newWeightList = [...weightList, newWeight];
      setWeightList(newWeightList);
    }
  };

  const handleAnalysisTypeChange = (SecID, sec_analysisType) => {
    if (analysisTypeList.length) {
      let newAnalysisTypeList = analysisTypeList.filter(
        (i) => i.SecID !== SecID
      );
      let newType = {};
      newType["SecID"] = SecID;
      newType["sec_analysisType"] = sec_analysisType;
      newAnalysisTypeList.push(newType);
      setAnalysisTypeList(newAnalysisTypeList);
    } else {
      let newType = {
        SecID: SecID,
        sec_analysisType: sec_analysisType,
      };
      let newAnalysisTypeList = [...analysisTypeList, newType];
      setAnalysisTypeList(newAnalysisTypeList);
    }
  };

  const updateQBank = async (QID, setInQBank) => {
    await apiCall(`admin/AddRemoveFrmQBank/${QID}`, "PUT", {
      inQBank: setInQBank,
    }).then(() => {
      getAssmt(AID);
    });
  };

  const updateWeights = async (weightList, AID) => {
    try {
      if (weightList.length) {
        let weightObj = { weightList: weightList, modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" ") };
        await apiCall(`admin/updateWeight/${AID}`, "PUT", weightObj);
        await apiCall(`analysis/updatesecscore/${AID}`, "GET");
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateAnalysisType = async (analysisTypeList, AID) => {
    try {
      //work here too for updating analysis score
      if (analysisTypeList.length) {
        let analysisTypeObj = { analysisTypeList: analysisTypeList,modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" ")};
        let resp = await apiCall(`admin/updatesectiontype/${AID}`, "PUT", analysisTypeObj);
        if(resp?.data?.error || (!resp?.data))
          return false;
        if (assmtObj.hasResponse === true)
          await apiCall(`analysis/updatesecscore/${AID}`, "GET");
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleOnClickEdit = ({AID, editOnly}) => {
    navigate(`/admin/editAssmt/${AID}`, { state: { textOnly: false, editOnly } });

  };

  const handleOnClickEditText = (id) => {
    navigate(`/admin/editAssmt/${id}`, { state: { textOnly: true, editOnly: false } });

  };

  const handleOnClickPublish = async (id) => {
    let flag = true;
    for (let i = 0; i < sectionList.length; i++) {
      if (sectionList[i].nfc === "1" && sectionList[i].nfcData.length === 0) {
        flag = false;
        setMessage(`NFC checked but range not provided in Section #${i + 1}`);
        setStatus(false);
        setToast(true);
        break;
      }
      if (sectionList[i].sType === "") {
        flag = false;
        setMessage(`Analysis type not selected for Section #${i + 1}`);
        setStatus(false);
        setToast(true);
        break;
      }
      if (sectionList[i].qIDs.length === 0) {
        flag = false;
        setMessage(
          `No question found in Section #${
            i + 1
          }, to publish add atleast one question and choice`
        );
        setStatus(false);
        setToast(true);
      } else {
        let questions = sectionList[i].qIDs;
        for (let j = 0; j < questions.length; j++) {
          if (questions[j].cIDs.length === 0) {
            flag = false;
            setMessage(
              `No choices found in Section #${i + 1}, question #${
                j + 1
              }, to publish add atleast one choice and it's weightage`
            );
            setStatus(false);
            setToast(true);
          }
        }
      }
    }
    if (flag) {
      try{
        let publish = await apiCall(`admin/publishAssmt/${id}`, "PUT");
        if(publish?.data?.error)
          throw publish.data;
        if(!publish?.data)
          throw "Network Error!"

          const { data } = await apiCall(`admin/`);
          setAssmt(data);
          setMessage("Assessment published!");
          setStatus(true);
          setToast(true);
          navigate("/admin");
      }catch(err){
        console.log("error while publishing assessment : ",err);
        setMessage("Unable to publish assessment!");
        setStatus(false);
        setToast(true);
        return;
      }
    }
  };

  const handleOnClickUnpublish = async (id) => {
    let unpublish = await apiCall(`admin/unpublishAssmt/${id}`, "PUT");
    if (unpublish) {
      const { data } = await apiCall(`admin/`);
      setAssmt(data);
      setMessage("Assessment unpublished!");
      setStatus(true);
      setToast(true);

      navigate("/admin");
    }
  };

  const handleOnClickDelete = async (id) => {
    try{
      let deleteAssmt = await apiCall(`admin/deleteAssmt/${id}`, "DELETE");
      console.log("delete : ",deleteAssmt);
      if(deleteAssmt?.data?.error)
        throw deleteAssmt.data;
      //handling network error
      if(!deleteAssmt?.data)
        throw {message: 'Network Error!'}

        const { data } = await apiCall("admin/");
        setAssmt(data);
        setMessage(deleteAssmt?.data?.message);
        setStatus(true);
        setToast(true);
        navigate("/admin", {
          replace: false,
        });
    }catch(err){
      setMessage(err.message);
      setStatus(false);
      setToast(true);
      return;
    }
  };

  function showNFCModal() {
    setIsOpenNFCModal(!isOpenNFCModal);
  }

  const updateQLvlOptions = async (hcsCheckedList, AID) => {
    try {
      if (hcsCheckedList.length) {
        let hcsCheckBoxObj = { hcsCheckBoxList: hcsCheckedList, modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" "), AID};
        await apiCall(`admin/updatehcscheckbox`, "PUT", hcsCheckBoxObj);
        if (assmtObj.hasResponse === true) {
          await apiCall(`analysis/updatehcsscore/${AID}`, "PUT");
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateExcludeOptions = async (excludeCheckedList, AID) => {
    try {
      if (excludeCheckedList.length !== 0) {
        let excludeCheckBoxObj = { excludeCheckBoxList: excludeCheckedList, modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" "), AID };
        await apiCall(`admin/updateexcludecheckbox`, "PUT", excludeCheckBoxObj);
        if (assmtObj.hasResponse === true) {
          await apiCall(`analysis/updatesecscore/${AID}`, "GET");
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  function handleHCSCheckedChange(QID, value) {
    if (hcsCheckedList.length) {
      let newHcsCheckedList = hcsCheckedList.filter((i) => i.QID !== QID);
      let newType = {};
      newType["QID"] = QID;
      newType["hcs"] = value;

      newHcsCheckedList.push(newType);
      setHcsCheckedList(newHcsCheckedList);
    } else {
      let newType = {
        QID: QID,
        hcs: value,
      };
      let newHcsCheckedList = [...hcsCheckedList, newType];
      setHcsCheckedList(newHcsCheckedList);
    }
  }

  function handleExcludeCheckedChange(QID, value) {
    if (excludeCheckedList.length !== 0) {
      let newExcludeCheckedList = excludeCheckedList.filter(
        (i) => i.QID !== QID
      );
      let newType = {};
      newType["QID"] = QID;
      newType["exclude"] = value;
      newExcludeCheckedList.push(newType);
      setExcludeCheckedList(newExcludeCheckedList);
    } else {
      let newType = {
        QID: QID,
        exclude: value,
      };
      let newExcludeCheckedList = [...excludeCheckedList, newType];
      setExcludeCheckedList(newExcludeCheckedList);
    }
  }

  function handleCommunicationCheckedChange(CID, communication) {
    setCommunicationCheckedList((currList) => {
      let found = false;
      const updatedList = currList.filter((i) => {
        if (i.CID === CID) found = true;
        return i.CID !== CID;
      });
      if (!found) return [...currList, { CID, communication }];
      return updatedList;
    });
  }

  const getQuestion = (SID, QID) => {
    const section = sectionList.find((sec) => sec.SecID === SID);
    const question = section?.qIDs.find((ques) => ques.QID === QID);
    return question;
  };

  const handleQuesLvlCommunicationChange = (SID, QID, communication) => {
    if (communication && !communicationChecked.includes(QID))
      setCommunicationChecked([...communicationChecked, QID]);
    else {
      setCommunicationChecked(communicationChecked.filter((id) => id !== QID));
      const question = getQuestion(SID, QID);
      const updates = [];
      const CIDs = question?.cIDs.map((choice) => {
        if (choice.communication === 1)
          updates.push({
            CID: choice.CID,
            communication: false,
          });
        return choice.CID;
      });
      setCommunicationCheckedList((currList) => {
        const updatedList = currList.filter((i) => !CIDs.includes(i.CID));
        return [...updatedList, ...updates];
      });
    }
  };

  const updateCommunicationCheckBox = async () => {
    try {
      if (communicationCheckedList.length) {
        await apiCall("admin/choices/communication", "PUT", {
          communicationList: communicationCheckedList,
          modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" "),
          AID
        });

        if (assmtObj.hasResponse)
          await apiCall(`analysis/communicationscore/${AID}`, "PUT");
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleNfcCheckedChange = (SID, nfc) => {
    let newNfcCheckedList = nfcCheckedList;
    if (newNfcCheckedList.length)
      newNfcCheckedList = newNfcCheckedList.filter((i) => i.SID !== SID);
    setNfcCheckedList([...newNfcCheckedList, { SID, nfc }]);
  };

  const updateNfcCheckbox = async () => {
    try {
      if (nfcCheckedList.length) {
        await apiCall("admin/updatenfccheckbox", "PUT", {
          nfcList: nfcCheckedList,
          modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" "),
          AID
        });
        if (assmtObj.hasResponse)
          await apiCall(`analysis/updatesecscore/${AID}`, "GET");
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#f0f7fc",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      maxHeight: "50%",
      width: "50%",
      transform: "translate(-50%, -50%)",
      overflow: "auto",
      backgroundColor: "white",
    },
  };

  function handleNFCAdd(sidx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;
    let nfc = {
      startWeight: "",
      endWeight: "",
      eqWeight: "",
    };
    newNFCList.push(nfc);
    newSectionList[sidx].nfcData = newNFCList;
    setSectionList(newSectionList);
  }

  function handleNFCRemove(sidx, NFCIdx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;
    if (newNFCList.length === 1 && newSectionList[sidx].nfc === "1") {
      setMessage(`Since NFC is checked, one range required in this section`);
      setStatus(false);
      setToast(true);
    } else {
      if (newNFCList[NFCIdx].NFCID) {
        let newDeletedNFCList = [...deletedNFCList];
        newDeletedNFCList.push(newNFCList[NFCIdx].NFCID);
        setDeletedNFCList(newDeletedNFCList);
      }
      newNFCList.splice(NFCIdx, 1);
      setSectionList(newSectionList);
    }
  }

  function handleNFCChange(e, sidx, NFCIdx) {
    let newSectionList = [...sectionList];
    let newNFCList = newSectionList[sidx].nfcData;
    const { name, value } = e.target;
    newNFCList[NFCIdx][name] = value;
    newSectionList[sidx].nfcData = newNFCList;
    setSectionList(newSectionList);
  }

  function checkNFCOverlap(sidx) {
    const section = sectionList[sidx];
    if (!isNFCComplete(section.nfcData)) {
      setMessage(`Complete all the fields in the NFC range before submitting`);
      setStatus(false);
      setToast(true);
      return;
    }
    if (!validateNFCOverlap(sidx, "save")) return;
    showNFCModal();
    updateNFC();
  }

  function validateNFCOverlap(sidx, stage) {
    let newSectionList = [...sectionList];

    let isSectionComplete =
      stage === "save" || isNFCComplete(newSectionList[sidx].nfcData);

    if (isSectionComplete) {
      const [isValid, reason] = validateNFCOverlapHelper(
        newSectionList[sidx].nfcData
      );
      if (!isValid) {
        setMessage(reason);
        setStatus(false);
        setToast(true);
        return false;
      }
    }
    return true;
  }

  async function updateNFC() {
    let updateDone = await apiCall(`admin/updateNfc/${AID}`, "PUT", {
      sectionList,
      deletedNFCList,
    });
    if (updateDone) {
      getAssmt(AID);
      setMessage(`NFC data updated successfully!`);
      setStatus(true);
      setToast(true);
    }
    if (assmtObj.hasResponse === true)
      await apiCall(`analysis/updatesecscore/${AID}`, "GET");
  }

  async function updateSectionGraph(
    sectionGraphObj,
    questionLabels,
    sectionLabels,
    updatesMade
  ) {
    let flag = false;
    let e_msgs = [];
    try {
      const keys = Object.keys(sectionGraphObj);
      keys.forEach((key) => {
        let ik = sectionList.findIndex((section) => section.SecID === key);
        if (sectionGraphObj[key] === true) {
          if (sectionLabels.hasOwnProperty(key)) {
            if (sectionLabels[key].trim() === "") {
              e_msgs.push(`Section ${ik + 1} label is empty`);
              flag = true;
            }
            let section = sectionList[ik];
            section.qIDs.forEach((question, iq) => {
              if (questionLabels.hasOwnProperty(question.QID)) {
                if (questionLabels[question.QID].trim() === "") {
                  e_msgs.push(
                    `Section ${ik + 1}, Question ${iq + 1} label is empty`
                  );
                  flag = true;
                }
              } else {
                e_msgs.push(
                  `Section ${ik + 1}, Question ${iq + 1} label is missing`
                );
                flag = true;
              }
            });
          } else {
            e_msgs.push(`Section ${ik + 1} label is missing`);
            flag = true;
            let section = sectionList[ik];
            section.qIDs.forEach((question, iq) => {
              if (questionLabels.hasOwnProperty(question.QID)) {
                if (questionLabels[question.QID].trim() === "") {
                  e_msgs.push(
                    `Section ${ik + 1}, Question ${iq + 1} label is empty`
                  );
                  flag = true;
                }
              } else {
                e_msgs.push(
                  `Section ${ik + 1}, Question ${iq + 1} label is missing`
                );
                flag = true;
              }
            });
          }
        }
      });
      if (!flag) {
        keys.forEach((key) => {
          if (sectionGraphObj[key] === true) {
            console.log(sectionLabels[key]);
            const result = apiCall("/admin/sections/graph", "PUT", {
              sectionGraph: sectionGraphObj,
              questionLabels,
              sectionLabels,
            });
            updatesMade = true;
          } else {
            let qLabel = sectionList
              .find((section) => section.SecID === key)
              .qIDs.reduce((acc, question) => {
                acc[question.QID] = "";
                return acc;
              }, {});
            let sLabel = { [key]: "" };
            const result = apiCall("/admin/sections/graph", "PUT", {
              sectionGraph: sectionGraphObj,
              questionLabels: qLabel,
              sectionLabels: sLabel,
            });
            updatesMade = true;
          }
        });
        setSectionList((prev) => {
          const updatedList = [...prev];
          keys.forEach((key) => {
            const idx = updatedList.findIndex(
              (section) => section.SecID === key
            );
            updatedList[idx].sectionLabel = sectionLabels[key] || "";
            updatedList[idx].qIDs.forEach((question) => {
              question.label = questionLabels[question.QID] || "";
            });
          });
          return updatedList;
        });

        return { updatesMade, e_msgs, flag: false };
      } else {
        return { updatesMade: false, e_msgs, flag: true };
      }
    } catch (error) {
      return false;
    }
  }
 
  // modify visibility modal related functions 
  const handleVisibility = () => {
    setModifyVisibilityModal(true);
  }

  const handleCloseVisibilityModal = () => {
    setModifyVisibilityModal(false);
  }

  const updateAssessmentStatus = (value) => {
    setAssmtObj((obj)=>{
      return {...obj, isPublic: value}
    })

    setAssmt((assessments)=>{
      return assessments.map((asmt)=>{
        return asmt.AID === AID ? {...asmt, isPublic: value ? 'Public' : 'Restricted'} : asmt;
      })
    })
  }

  return (
    <div>
      {loginStatus === true && loggedInUser.group === "Admin" ? (
        <>
          <Helmet>
            <title>Helix Employability Tool | View Assessment</title>
          </Helmet>
          <Navigate />
          <h6>
            <a href="#" onClick={() => navigate("/admin")}>
              &lt;Back
            </a>
          </h6>
          <ToastMessage
            toast={toast}
            onClose={() => setToast(!toast)}
            message={message}
            status={status}
          />

          {/* Modify Visibility Modal  */}
          {
            modifyVisibilityModal && (
              <ModifyVisibilityModal 
                showModal={modifyVisibilityModal}
                onHideModal={handleCloseVisibilityModal}
                assessmentStatus={assmtObj.isPublic}
                AID={AID}
                updateAssessmentStatus={updateAssessmentStatus}
                groups={groups}
                setGroups={setGroups}
              />
            )
          }

          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{assmtObj.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to permanently delete the assessment ?{" "}
              <br />
              The questions added in the Question Bank will also be removed.
              <br />
              <br />
              <p>
                you must enter "<span style={{ color: "red" }}>delete</span>" to
                proceed.
              </p>
              <input
                onChange={(e) => {
                  setDeleteOpr(e.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button
                disabled={deleteOpr === "delete" ? false : true}
                variant="danger"
                onClick={() => handleOnClickDelete(AID)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Helix</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {`All user response for ${assmtObj.name} is generated as .csv file and ready to dowload!`}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Cancel
              </Button>
              <CSVLink
                data={allUserRes} // This is the flattened data with section names and scores added
                headers={[
                  { label: "Group Name", key: "group_name" },
                  { label: "User ID", key: "UID" },
                  { label: "User Assmt ID", key: "UAID" },
                  { label: "Reference Date", key: "referenceDate" },
                  // Add section names for scores dynamically
                  ...(allUserRes.length > 0
                    ? Object.keys(allUserRes[0])
                        .filter(
                          (key) =>
                            key.startsWith("Section ") &&
                            !key.startsWith("Section Name")
                        ) // Only add section score columns here
                        .map((key) => ({
                          label: key.replace(/Section /, ""), // Clean up the section names
                          key,
                        }))
                    : []),
                  // Now add the detailed section data (same as before)
                  ...(allUserRes.length > 0
                    ? Object.keys(allUserRes[0])
                        .filter(
                          (key) =>
                            key.startsWith("Section Name") ||
                            key.startsWith("Score Obtained") ||
                            key.startsWith("Question") ||
                            key.startsWith("Choice Picked") ||
                            key.startsWith("Weightage of Choice") ||
                            key.startsWith("Weightage of Response") ||
                            key.startsWith("Type of Question")
                        )
                        .map((key) => ({
                          label: key.replace(/\d+-\d+/, "").replace(/\d+/, ""),
                          key,
                        }))
                    : []),
                ]}
                filename={`All_User_Responses_${new Date().toLocaleDateString()}.csv`}
                target="_blank"
              >
                <Button variant="success" onClick={handleClose2}>
                  Download
                </Button>
              </CSVLink>
            </Modal.Footer>
          </Modal>
          <div
            className="newAssmt"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="container"
              style={{ width: "100%", right: "1%", position: "relative" }}
            >
              <Card className="full-width-card" style={{ width: "100%" }}>
                <Card.Body>
                  <Card.Title>{assmtObj.name}</Card.Title>
                  <label className="mb-2 text-muted">
                    {assmtObj.description}
                  </label>
                  <br></br>
                  {assmtObj.url && assmtObj.url !== "" ? (
                    <>
                      <label className="mb-2 text-muted">
                        To learn about your results please visit{" "}
                        {assmtObj.url.split(",").map((url, index, array) => (
                          <React.Fragment key={index}>
                            <a
                              href={url.trim()}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => {
                                if (
                                  !window.confirm(
                                    "You will be leaving helix.com. Are you sure?"
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            >
                              {url.trim()}
                            </a>
                            {index !== array.length - 1 && ",   "}
                          </React.Fragment>
                        ))}
                      </label>
                      <br></br>
                    </>
                  ) : null}
                  <label
                    className="mb-2 text-muted"
                    style={{ marginRight: "1rem" }}
                  >
                    Created on:{" "}
                    {moment
                      .utc(assmtObj.TOC)
                      .tz("America/New_York")
                      .format("MM/DD/YYYY hh:mm:ss A")}{" "}
                  </label>
                  <label
                    className="mb-2 text-muted"
                    style={{ marginRight: "1rem" }}
                  >
                    Published: {assmtObj.published}
                  </label>
                  {assmtObj.published === "Yes" ? (
                    <label
                      className="mb-2 text-muted"
                      style={{ marginRight: "1rem" }}
                    >
                      Published on:{" "}
                      {moment
                        .utc(assmtObj.TOP)
                        .tz("America/New_York")
                        .format("MM/DD/YYYY hh:mm:ss A")}{" "}
                    </label>
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>
            </div>
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="w-25 fixed" style={{ width: "25%" }}>
                <Form.Group className="mb-3 mt-4">
                  {assmtObj.hasResponse === true ? (
                    <>
                      <Button
                        variant="light"
                        style={{ color: "#163172" }}
                        className="createAssmt-btn mb-3"
                        disabled={analysisBtnDisabled || prevBool}
                        onClick={() => handleOnClickView(AID)}
                      >
                        <FaEye style={{ color: "#163172", fontSize: "20px" }} />
                        &nbsp;&nbsp; View response
                      </Button>
                      <Button
                        variant="light"
                        style={{ color: "#163172" }}
                        className="createAssmt-btn mb-3"
                        disabled={analysisBtnDisabled || prevBool}
                        onClick={() => {
                          exportAllUserRes(AID);
                          handleShow2();
                        }}
                      >
                        <TbFileDownload
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Export response
                      </Button>
                      {/* </CSVLink> */}

                      <Button
                        variant="light"
                        style={{ color: "#163172" }}
                        className="createAssmt-btn mb-3"
                        disabled={analysisBtnDisabled || prevBool}
                        onClick={() => {
                          handleOnClickEdit({AID: AID, editOnly: false});
                        }}
                      >
                        <TbFiles
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Copy & Edit
                      </Button>
                      <Button
                        variant="light"
                        style={{ color: "#163172" }}
                        className="createAssmt-btn mb-3"
                        disabled={analysisBtnDisabled || prevBool}
                        onClick={() => {
                          handleOnClickEditText(AID);
                        }}
                      >
                        <TbFileSettings
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Edit
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="light"
                      style={{ color: "#163172" }}
                      className="createAssmt-btn mb-3"
                      disabled={analysisBtnDisabled || prevBool}
                      onClick={() => {
                        handleOnClickEdit({AID: AID, editOnly: true});
                      }}
                    >
                      <TbFileSettings
                        style={{ color: "#163172", fontSize: "20px" }}
                      />
                      &nbsp;&nbsp; Edit
                    </Button>
                  )}

                  {assmtObj.published === "Yes" ? (
                    <>
                      <Button
                        variant="light"
                        style={{ color: "#163172" }}
                        className="createAssmt-btn mb-3"
                        disabled={
                          analysisBtnDisabled ||
                          prevBool ||
                          sectionList.length === 0
                        }
                        onClick={() => handleOnClickUnpublish(AID)}
                      >
                        <TbFileX
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Unpublish
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="light"
                        style={{ color: "#163172" }}
                        className="createAssmt-btn mb-3"
                        disabled={
                          analysisBtnDisabled ||
                          prevBool ||
                          sectionList.length === 0
                        }
                        onClick={() => handleOnClickPublish(AID)}
                      >
                        <TbFileCheck
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Publish
                      </Button>
                    </>
                  )}

                  {analysisBtnDisabled === false ? (
                    <Button
                      variant="light"
                      style={{ color: "#163172" }}
                      className="createAssmt-btn mb-3"
                      disabled={prevBool || sectionList.length === 0}
                      onClick={() => toggleAnalysisBtn()}
                    >
                      <AiOutlineRadarChart
                        style={{ color: "#163172", fontSize: "20px" }}
                      />
                      &nbsp;&nbsp; Edit Analysis Options
                    </Button>
                  ) : (
                    <Button
                      variant="light"
                      style={{ color: "#163172" }}
                      className="createAssmt-btn mb-3"
                      disabled={prevBool}
                      onClick={async () => {
                        try{
                        let updatesMade = false;
                        let flag = false;
                        let e_mesg = [];
                        console.log("analysis : ",analysisTypeList);
                        if (analysisTypeList.length) {

                          const result = await updateAnalysisType(
                            analysisTypeList,
                            AID
                          );
                          updatesMade = updatesMade || result;
                        }
                        if (nfcCheckedList.length) {
                          const result = await updateNfcCheckbox();
                          updatesMade = updatesMade || result;
                        }
                        if (weightList.length) {
                          const result = await updateWeights(weightList, AID);
                          updatesMade = updatesMade || result;
                        }
                        if (communicationCheckedList.length) {
                          const result = await updateCommunicationCheckBox();
                          updatesMade = updatesMade || result;
                        }
                        if (hcsCheckedList.length) {
                          const result = await updateQLvlOptions(
                            hcsCheckedList,
                            AID
                          );
                          updatesMade = updatesMade || result;
                        }
                        if (excludeCheckedList.length) {
                          const result = await updateExcludeOptions(
                            excludeCheckedList,
                            AID
                          );
                          updatesMade = updatesMade || result;
                        }
                        console.log(
                          sectionGraphObj,
                          questionLabels,
                          sectionLabels,
                          "here"
                        );
                        if (
                          Object.keys(sectionGraphObj).length > 0 ||
                          Object.keys(questionLabels).length > 0 ||
                          Object.keys(sectionLabels).length > 0
                        ) {
                          let object = await updateSectionGraph(
                            sectionGraphObj,
                            questionLabels,
                            sectionLabels,
                            updatesMade
                          );
                          console.log(object, "object");
                          updatesMade = object.updatesMade;
                          e_mesg = object.e_msgs;
                          flag = object.flag;
                          // const result = await apiCall("/admin/sections/graph", "PUT", {
                          //   sectionGraph: sectionGraphObj,
                          //   questionLabels,
                          //   sectionLabels,
                          // });
                          // console.log(result, "result");
                          // updatesMade = updatesMade || result;
                        }
                        if (updatesMade) {
                          getAssmt(AID);
                          exportAllUserRes(AID);
                          setMessage("Analysis options updated");
                          setStatus(true);
                          setToast(true);
                          setAnalysisTypeList([]);
                          setNfcCheckedList([]);
                          setWeightList([]);
                          setCommunicationCheckedList([]);
                          setHcsCheckedList([]);
                          setSectionGraphObj({});
                          setQuestionLabels({});
                          setSectionLabels({});
                          toggleAnalysisBtn();
                          navigate("/admin");
                        } else {
                          if (flag) {
                            setMessage(e_mesg);
                            setStatus(false);
                            setToast(true);
                          } else {
                            setMessage("No changes recorded!");
                            setStatus(false);
                            setToast(true);

                            const flattenedQIDs = [];

                            sectionList.forEach((section) => {
                              const qIDs = section.qIDs || [];
                              flattenedQIDs.push(...qIDs);
                            });

                            setCommunicationChecked(
                              communicationChecked.filter((qID) => {
                                const question = flattenedQIDs.find(
                                  (q) => q.QID === qID
                                );
                                return question.cIDs
                                  .map((c) => c.communication)
                                  .includes(1);
                              })
                            );

                            setSectionGraphObj({});
                            setQuestionLabels({});
                            setSectionLabels({});
                            toggleAnalysisBtn();
                          }
                        }
                      }
                      catch(err){
                        console.log("Error in Editing Assessment : ",err);
                        setMessage("Sorry, something went wrong!");
                        setStatus(false);
                        setToast(true);
                        navigate("/admin");
                        }
                      }}
                    >
                      <RiSaveFill
                        style={{ color: "#163172", fontSize: "20px" }}
                      />
                      &nbsp;&nbsp; Save Analysis Options
                    </Button>
                  )}

                  <Button
                    variant="light"
                    style={{ color: "#163172" }}
                    className="createAssmt-btn mb-3"
                    disabled={analysisBtnDisabled}
                    onClick={() => toggleprev()}
                  >
                    {prevBool === false ? (
                      <>
                        <TbFileSymlink
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Preview
                      </>
                    ) : (
                      <>
                        <MdArrowBack
                          style={{ color: "#163172", fontSize: "20px" }}
                        />
                        &nbsp;&nbsp; Back
                      </>
                    )}
                  </Button>

                  <Button
                    variant="light"
                    style={{ color: "#163172" }}
                    className="createAssmt-btn mb-3"
                    disabled={analysisBtnDisabled || prevBool}
                    onClick={handleVisibility}
                  >
                    <AiOutlineTeam
                      style={{ color: "#163172", fontSize: "20px" }}
                    />
                    &nbsp;&nbsp; Modify Visibility
                  </Button>

                  <Button
                    variant="light"
                    style={{ color: "#163172" }}
                    className="createAssmt-btn mb-3"
                    disabled={analysisBtnDisabled || prevBool}
                    onClick={handleShow1}
                  >
                    <AiTwotoneDelete
                      style={{ color: "#BD4B4B", fontSize: "20px" }}
                    />
                    &nbsp;&nbsp; Delete
                  </Button>
                </Form.Group>
              </div>
              <div className="assmt-b">
                {prevBool === false ? (
                  <>
                    {sectionList.map((singleSec, sidx) => {
                      return (
                        <div key={sidx}>
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey={sidx}>
                              <Accordion.Header>
                                Section #{sidx + 1}
                                <div
                                  id="otyjb"
                                  key={sidx}
                                  style={{
                                    position: "absolute",
                                    right: "0",
                                    marginRight: "3rem",
                                    gap: "25px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <RModal
                                    isOpen={isOpenNFCModal}
                                    style={customStyles}
                                  >
                                    <ol key={sidx}>
                                      {sectionList[currNFCRangeIdx].nfcData.map(
                                        (NFCItem, NFCIdx) => (
                                          <Stack gap={3} key={NFCIdx}>
                                            <li>
                                              <NFCListSet
                                                editing={editNFCRange}
                                                NFCIdx={NFCIdx}
                                                sidx={currNFCRangeIdx}
                                                NFCItem={NFCItem}
                                                handleNFCRemove={(idx) =>
                                                  handleNFCRemove(
                                                    currNFCRangeIdx,
                                                    idx
                                                  )
                                                }
                                                handleNFCChange={(e, index) =>
                                                  handleNFCChange(
                                                    e,
                                                    currNFCRangeIdx,
                                                    index
                                                  )
                                                }
                                                validateNFCOverlap={() =>
                                                  validateNFCOverlap(
                                                    currNFCRangeIdx
                                                  )
                                                }
                                              />
                                            </li>
                                          </Stack>
                                        )
                                      )}
                                    </ol>
                                    <div
                                      style={{
                                        width: "100%",
                                        paddingLeft: "30px",
                                        display: "flex",
                                      }}
                                    >
                                      <div>
                                        <Button
                                          disabled={editNFCRange}
                                          className="ms-auto"
                                          variant="outline-light"
                                          style={{
                                            color: "#163172",
                                            fontSize: "14px",
                                          }}
                                          onClick={() => {
                                            handleNFCAdd(currNFCRangeIdx);
                                          }}
                                        >
                                          ✚ Set
                                        </Button>
                                      </div>
                                      <div style={{ marginLeft: "10px" }}>
                                        {editNFCRange === false ? (
                                          <Button
                                            className="ms-auto"
                                            variant="outline-light"
                                            style={{
                                              color: "#163172",
                                              fontSize: "14px",
                                            }}
                                            onClick={() => {
                                              checkNFCOverlap(currNFCRangeIdx);
                                            }}
                                          >
                                            <RiSaveFill
                                              style={{
                                                color: "#163172",
                                                fontSize: "20px",
                                              }}
                                            />
                                            &nbsp;&nbsp; Save
                                          </Button>
                                        ) : (
                                          <Button
                                            className="ms-auto"
                                            variant="outline-light"
                                            style={{
                                              color: "#163172",
                                              fontSize: "14px",
                                            }}
                                            onClick={() => {
                                              editNFCRangefunc();
                                            }}
                                          >
                                            <TbFileSettings
                                              style={{
                                                color: "#163172",
                                                fontSize: "20px",
                                              }}
                                            />
                                            &nbsp;&nbsp; Edit
                                          </Button>
                                        )}
                                      </div>
                                      <div style={{ marginLeft: "10px" }}>
                                        <Button
                                          // disabled={editing}
                                          className="ms-auto"
                                          variant="outline-light"
                                          style={{
                                            color: "#163172",
                                            fontSize: "14px",
                                          }}
                                          onClick={() => {
                                            showNFCModal();
                                          }}
                                        >
                                          <AiOutlineCloseCircle
                                            style={{
                                              color: "#BD4B4B",
                                              fontSize: "20px",
                                            }}
                                          />
                                          &nbsp;&nbsp; Close
                                        </Button>
                                      </div>
                                    </div>
                                  </RModal>

                                  {singleSec.nfc && (
                                    <Button
                                      // disabled={editing}
                                      style={{ width: "125px" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        showNFCModal();
                                        setCurrNFCRangeIdx(sidx);
                                      }}
                                    >
                                      NFC Range
                                    </Button>
                                  )}

                                  <Form.Check
                                    disabled={!analysisBtnDisabled}
                                    type="checkbox"
                                    name="NFC"
                                    label={"NFC"}
                                    id="nfccheckbox"
                                    defaultChecked={singleSec.nfc}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onChange={(e) => {
                                      handleNfcCheckedChange(
                                        singleSec.SecID,
                                        e.target.checked
                                      );
                                    }}
                                    style={{ alignSelf: "center" }}
                                  />

                                  <Form.Check
                                    disabled={!analysisBtnDisabled}
                                    type="checkbox"
                                    name="sectionGraph"
                                    label={"Section Graph"}
                                    defaultChecked={singleSec.sectionGraph}
                                    id="sectionGraph"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onChange={(e) => {
                                      if (
                                        singleSec.sectionGraph ===
                                        e.target.checked
                                      ) {
                                        const obj = {
                                          ...sectionGraphObj,
                                        };
                                        delete obj[singleSec.SecID];
                                        setSectionGraphObj(obj);
                                      } else
                                        setSectionGraphObj({
                                          ...sectionGraphObj,
                                          [singleSec.SecID]: e.target.checked,
                                        });
                                    }}
                                    style={{ alignSelf: "center" }}
                                  />

                                  {!singleSec ||
                                  analysisBtnDisabled === false ? (
                                    <div
                                      style={{
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        width: "208px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Analysis type:{" "}
                                      {singleSec.sType === null ||
                                      singleSec.sType === ""
                                        ? "Not Defined"
                                        : singleSec.sType}
                                    </div>
                                  ) : (
                                    <Form.Select
                                      name="sAnalysisType"
                                      style={{ width: "150px" }}
                                      defaultValue={singleSec.sType}
                                      onChange={(e) => {
                                        handleAnalysisTypeChange(
                                          singleSec.SecID,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="" disabled selected>
                                        Analysis Type
                                      </option>
                                      <option
                                        key={"weighted_avg"}
                                        value={"weighted_avg"}
                                      >
                                        Weighted Avg
                                      </option>
                                      <option key={"average"} value={"average"}>
                                        Average
                                      </option>
                                      <option key={"sum"} value={"sum"}>
                                        Sum
                                      </option>
                                      <option
                                        key={"weighted_sum"}
                                        value={"weighted_sum"}
                                      >
                                        Weighted Sum
                                      </option>
                                    </Form.Select>
                                  )}
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <Stack>
                                  <Stack
                                    direction="horizontal"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      {singleSec.sName.length !== 0 ? (
                                        <label>Name: {singleSec.sName}</label>
                                      ) : (
                                        ""
                                      )}
                                      {singleSec.sDesc.length !== 0 ? (
                                        <label>
                                          Description: {singleSec.sDesc}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {(sectionGraphObj[singleSec.SecID] ||
                                      singleSec.sectionGraph) && (
                                      <Form.Group>
                                        <Stack direction="horizontal">
                                          <Form.Label className="mb-0">
                                            Graph Title
                                          </Form.Label>
                                          <Form.Control
                                            disabled={!analysisBtnDisabled}
                                            style={{ width: "200px" }}
                                            defaultValue={
                                              singleSec.sectionLabel
                                            }
                                            onChange={(e) => {
                                              if (
                                                singleSec.label ===
                                                  e.target.value.trim() &&
                                                sectionLabels[singleSec.SecID]
                                              ) {
                                                const obj = {
                                                  ...sectionLabels,
                                                };
                                                delete obj[singleSec.SecID];
                                                setSectionLabels(obj);
                                              } else
                                                setSectionLabels({
                                                  ...sectionLabels,
                                                  [singleSec.SecID]:
                                                    e.target.value.trim(),
                                                });
                                            }}
                                          />
                                        </Stack>
                                      </Form.Group>
                                    )}
                                  </Stack>
                                  {singleSec.qIDs.length !== 0 ? (
                                    <div>
                                      <br /> <label>Questions:</label>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <Stack>
                                    <ol>
                                      {singleSec.qIDs.map((singleQue, qidx) => (
                                        <li key={qidx}>
                                          <div
                                            className="view-question-body"
                                            key={qidx}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <label>
                                                {singleQue.qQuestion}
                                              </label>
                                              {singleQue.inQBank === 1 ? (
                                                <Button
                                                  size="sm"
                                                  onClick={() => {
                                                    updateQBank(
                                                      singleQue.QID,
                                                      0
                                                    );
                                                  }}
                                                >
                                                  <FaFolderMinus />
                                                  &nbsp;&nbsp; QBank
                                                </Button>
                                              ) : (
                                                <Button
                                                  size="sm"
                                                  onClick={() => {
                                                    updateQBank(
                                                      singleQue.QID,
                                                      1
                                                    );
                                                  }}
                                                >
                                                  <FaFolderPlus />
                                                  &nbsp; QBank
                                                </Button>
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                textAlign: "center",
                                                height: "20px",
                                              }}
                                            >
                                              <Badge bg="secondary">
                                                Type: {singleQue.qType}
                                              </Badge>

                                              <Form.Check
                                                disabled={!analysisBtnDisabled}
                                                type="checkbox"
                                                name="hcs"
                                                label={"HCS"}
                                                id="hcscheckbox"
                                                defaultChecked={
                                                  singleQue.hcs === "1"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  handleHCSCheckedChange(
                                                    singleQue.QID,
                                                    e.target.checked
                                                  );
                                                }}
                                                style={{ marginLeft: "10px" }}
                                              />

                                              <Form.Check
                                                disabled={!analysisBtnDisabled}
                                                type={"checkbox"}
                                                checked={communicationChecked.includes(
                                                  singleQue.QID
                                                )}
                                                name={"communication"}
                                                label={"Communication"}
                                                className={
                                                  "communicationcheckbox"
                                                }
                                                id={
                                                  "communicationcheckbox" +
                                                  singleQue.QID
                                                }
                                                onChange={(e) => {
                                                  handleQuesLvlCommunicationChange(
                                                    singleSec.SecID,
                                                    singleQue.QID,
                                                    e.target.checked
                                                  );
                                                }}
                                                style={{ marginLeft: "10px" }}
                                              />

                                              <Form.Check
                                                disabled={!analysisBtnDisabled}
                                                type="checkbox"
                                                name="Exclude"
                                                label={"Exclude"}
                                                id="excludecheckbox"
                                                defaultChecked={
                                                  singleQue.exclude === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  handleExcludeCheckedChange(
                                                    singleQue.QID,
                                                    e.target.checked
                                                  );
                                                }}
                                                style={{ marginLeft: "10px" }}
                                              />
                                            </div>

                                            <br />

                                            {(sectionGraphObj[
                                              singleSec.SecID
                                            ] ||
                                              singleSec.sectionGraph) && (
                                              <Form.Group>
                                                <Stack
                                                  direction="horizontal"
                                                  gap={2}
                                                  className="mb-4"
                                                >
                                                  <Form.Label className="mb-0">
                                                    Label
                                                  </Form.Label>
                                                  <Form.Control
                                                    disabled={
                                                      !analysisBtnDisabled
                                                    }
                                                    style={{ width: "200px" }}
                                                    defaultValue={
                                                      singleQue.label
                                                    }
                                                    onChange={(e) => {
                                                      if (
                                                        singleQue.label ===
                                                          e.target.value.trim() &&
                                                        questionLabels[
                                                          singleQue.QID
                                                        ]
                                                      ) {
                                                        const obj = {
                                                          ...questionLabels,
                                                        };
                                                        delete obj[
                                                          singleQue.QID
                                                        ];
                                                        setQuestionLabels(obj);
                                                      } else
                                                        setQuestionLabels({
                                                          ...questionLabels,
                                                          [singleQue.QID]:
                                                            e.target.value.trim(),
                                                        });
                                                    }}
                                                  />
                                                </Stack>
                                              </Form.Group>
                                            )}

                                            <div>
                                              <Table bordered>
                                                <tbody>
                                                  <tr>
                                                    <td>#</td>
                                                    <td>Choices</td>
                                                    <td>Weightage</td>
                                                    {communicationChecked.includes(
                                                      singleQue.QID
                                                    ) && <td>Communication</td>}
                                                  </tr>

                                                  {singleQue.cIDs.map(
                                                    (singleCho, cidx) => (
                                                      <tr key={cidx}>
                                                        <td style={{width:'10px'}}>{cidx + 1}</td>
                                                        <td className="choice-col">
                                                          {singleCho.cName}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "15%",
                                                          }}
                                                        >
                                                          {analysisBtnDisabled ===
                                                          false ? (
                                                            <>
                                                              {singleCho.weight}
                                                            </>
                                                          ) : (
                                                            <Form.Control
                                                              type="number"
                                                              step="0.1"
                                                              placeholder="Weight"
                                                              name="weight"
                                                              defaultValue={
                                                                singleCho.weight
                                                              }
                                                              onChange={(e) => {
                                                                handleWeightChange(
                                                                  singleCho.CID,
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                        </td>
                                                        {communicationChecked.includes(
                                                          singleQue.QID
                                                        ) && (
                                                          <td>
                                                            <Form.Check
                                                              type="checkbox"
                                                              disabled={
                                                                !analysisBtnDisabled
                                                              }
                                                              defaultChecked={
                                                                singleCho.communication ===
                                                                1
                                                              }
                                                              onChange={(e) => {
                                                                handleCommunicationCheckedChange(
                                                                  singleCho.CID,
                                                                  e.target
                                                                    .checked
                                                                );
                                                              }}
                                                            />
                                                          </td>
                                                        )}
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </Table>
                                            </div>
                                          </div>
                                          <br />
                                        </li>
                                      ))}
                                    </ol>
                                  </Stack>
                                </Stack>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <br />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  // preview code to be added here
                  <PreviewAssmt sectionList={sectionList} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default ViewAssmt;
