import React, { createContext, useEffect, useState, useContext } from "react";
import axios from "axios";
import { getBaseUrl } from "../../base";
import { AccountContext } from "./AccountContext";
const { v4 } = require("uuid");

export const EditAssmtContext = createContext();

const EditAssmtContextProvider = ({ children }) => {
  const { loginStatus, loggedInUser, apiCall } = useContext(AccountContext);

  const [assmtID, setAssmtID] = useState("");
  const [assmtGrpID, setAssmtGrpID] = useState("");

  const [assmtObj, setAssmtObj] = useState({
    aName: "",
    aDesc: "",
    aUrl: "",
  });

  const [sectionList, setSectionList] = useState([]);

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  let AID = assmtID;

  useEffect(() => {
    if (!AID || AID === "") return;
    const getAssmtdetails = async (AID) => {
      const { data } = await apiCall(`admin/getAssmtDetailsByID/${AID}`);
      const cnt = await apiCall(`admin/getResponseCount/${AID}`);

      setAssmtObj({
        aid: AID,
        aName: data[0].name,
        aDesc: data[0].description,
        aUrl: data[0].url,
        published: data[0].published,
        hasResponse: cnt.data > 0 ? true : false,
      });
    };
    getAssmtdetails(AID);
  }, [AID, loggedInUser.jwtToken]);

  useEffect(() => {
    if (!AID || AID === "") return;
    const getAssmt = async (AID) => {
      const { data } = await apiCall(`admin/getAssmt/${AID}`);
      console.log("Assmnt : ",data);
      let sectionList = [];
      if (data.length === 0) {
        setSectionList([]);
      } else {
        data.forEach(async (sec, sidx, sArray) => {
          let sObj = {};
          sObj["SecID"] = sec.SecID;
          sObj["sName"] = sec.sec_name;
          sObj["sDesc"] = sec.sec_desc;
          sObj["sAnalysisType"] = sec.sec_analysisType;
          sObj["nfcData"] = sec.nfcData;
          sObj["nfc"] = `${sec.nfc}`;
          sObj["new"] = false; //indicates if the section already exists in the database for this assessment or not
          let qIDs = [];
          if (sec.questions.length === 0) sObj["qIDs"] = qIDs;
          sec.questions.forEach(async (que, qidx, qArray) => {
            let qObj = {};
            qObj["QID"] = que.QID;
            qObj["sidx"] = sidx;
            qObj["qQuestion"] = que.question;
            qObj["qType"] = que.type;
            qObj["hcs"] = que.hcs;
            qObj["motiv"] = que.motiv;
            qObj["CuAr"] = que.CuAr;
            qObj["CuArType"] = que.CuArType;
            qObj["tooltip"] = que.tooltip;
            qObj["new"] = false; //indicates if the question already exists in the database for this assessment or not
            let cIDs = [];
            if (que.choices.length === 0) qObj["cIDs"] = cIDs;
            que.choices.forEach(async (ch, cidx, cArray) => {
              let cObj = {};
              cObj["CID"] = ch.CID;
              cObj["qidx"] = qidx;
              cObj["sidx"] = sidx;
              cObj["cName"] = ch.choice;
              cObj["weight"] = ch.weightage;
              cObj["communication"] = `${ch.communication}`;
              cObj["motivType"] = ch.motivType;
              cObj["tooltip"] = ch.tooltip;
              cObj["placeholder"] = ch.placeholder;
              cObj["opt_type"] = ch.opt_type;
              cObj["options_list"] = ch.options_list;
              cObj["new"] = false; //indicates if the choice already exists in the database for this assessment or not
              cIDs.push(cObj);
              if (cidx === cArray.length - 1) {
                qObj["cIDs"] = cIDs;
              }
            });
            qIDs.push(qObj);
            if (qidx === qArray.length - 1) {
              sObj["qIDs"] = qIDs;
            }
          });
          sectionList.push(sObj);
          if (sidx === sArray.length - 1) {
            setSectionList(sectionList);
          }
        });
      }
    };
    getAssmt(AID);
  }, [AID, loggedInUser.jwtToken]);

  const handleSectionAdd = () => {
    setSectionList([
      ...sectionList,
      {
        sName: "",
        sDesc: "",
        qIDs: [],
        nfc: false,
        sAnalysisType: "",
        nfcData: [],
        new: true,
      },
    ]);
  };

  const handleSectionRemove = (idx) => {
    const newSectionList = [...sectionList];
    newSectionList.splice(idx, 1);
    setSectionList(newSectionList);
  };

  const handleSectionChange = (e, index) => {
    const { name, value } = e.target;
    const newSectionList = [...sectionList];
    newSectionList[index][name] = value;
    setSectionList(newSectionList);
  };

  const handleSectionList = (array, index, from) => {
    const newSectionList = [...sectionList];
    if (from === "ques") newSectionList[index]["qIDs"] = array;
    if (from === "nfcData") newSectionList[index]["nfcData"] = array;
    setSectionList(newSectionList);
  };

  const createAssmt = async (
    assmtObj,
    sectionList,
    textOnly,
    editOnly
  ) => {
    try {
      let assmt = {
        AID: assmtObj.aid,
        name: assmtObj.aName,
        desc: assmtObj.aDesc,
        url: assmtObj.aUrl,
        type: "individual",
      };
      if (!textOnly && !editOnly) {
        // when creating a new assessment, just create a new ID 
        assmt = {...assmt, AID: v4()}
        let assmtDone = await apiCall("admin/createAssmt", "POST", {
          ...assmt,
          created_by: [loggedInUser.first_name, loggedInUser.last_name].join(" "),
          modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" ")
        });
        console.log("Assmt Done : ",assmtDone);
        if (assmtDone?.data?.created) {
            let result = await Promise.all(
              sectionList.map(async (sObj, sSeq) => {
                let newSection = await apiCall(
                  `admin/createSection/${assmt.AID}`,
                  "POST",
                  {
                    sec_name: sObj.sName,
                    sec_desc: sObj.sDesc,
                    sec_analysisType: sObj.sAnalysisType,
                    sSeq: sSeq,
                    nfc: sObj.nfc,
                    // modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" ")
                  }
                );

                if (newSection?.data?.error || !newSection?.data) return false;

                let isErrorFree = true;
                if (newSection) {
                  let ans = await Promise.all(
                    sObj.qIDs.map(async (qObj, qSeq) => {
                      let cList = [];
                      qObj.cIDs.forEach((c, cSeq) => {
                        let cObj = {
                          choice: c.cName,
                          weightage: c.weight,
                          cSeq: cSeq,
                          communication: c.communication,
                          motivType: c.motivType,
                          tooltip: c.tooltip,
                          placeholder: c.placeholder,
                          opt_type: c.opt_type,
                          options_list: c.options_list,
                        };
                        cList.push(cObj);
                      });
                      let resp = apiCall(
                        `admin/createQuestion/${assmt.AID}/${newSection.data}`,
                        "POST",
                        {
                          question: qObj.qQuestion,
                          type: qObj.qType,
                          choices: cList,
                          qSeq: qSeq,
                          hcs: qObj.hcs,
                          motiv: qObj.motiv,
                          CuAr: qObj.CuAr,
                          CuArType: qObj.CuArType,
                        }
                      );
                      return resp;
                    })
                  );

                  ans.forEach((resp) => {
                    if (resp?.data?.error || !resp?.data) isErrorFree = false;
                  });

                  if (sObj.nfcData.length !== 0) {
                    let res = await apiCall(
                      `admin/createNfc/${assmt.AID}/${newSection.data}`,
                      "POST",
                      {
                        nfcData: sObj.nfcData,
                      }
                    );

                    if (res?.data?.error || !res?.data) isErrorFree = false;
                  }
                }
                return isErrorFree;
              })
            );

          

            //checking for API errors if any
            if(result.includes(false))
              return false;

          return assmtDone?.data?.AID;
        }
        return false;
      } 
      else if(editOnly){
        //here we know that the assessment ahs already been created, we just need to update the assessment
        //here updating the assessment means we can add new sections, new questions and new choices as well
      
        let resp = await apiCall(
          `admin/updateAssessment/${assmtObj.aid}`,
          "POST",
          {
            assmtObj: assmt,
            sectionList,
            modified_by : [loggedInUser.first_name, loggedInUser.last_name].join(" ")
          }
        );

        if(resp?.data?.error || resp?.name === "AxiosError")
          return false;
           
        return true;
      }
      else {
        let assmtDone = await apiCall(`admin/updateAssmt/${AID}`, "PUT", {
          ...assmt,
          modified_by: [loggedInUser.first_name, loggedInUser.last_name].join(" ")
        });
        if (assmtDone) {
          await sectionList.forEach(async (sObj, sSeq) => {
            let newSection = await apiCall(
              `admin/updateSection/${sObj.SecID}`,
              "PUT",
              {
                sec_name: sObj.sName,
                sec_desc: sObj.sDesc,
              }
            );
            if (newSection) {
              await sObj.qIDs.forEach(async (qObj) => {
                console.log(qObj);
                let cList = [];
                qObj.cIDs.forEach((c) => {
                  let cObj = {
                    CID: c.CID,
                    choice: c.cName,
                    tooltip: c.tooltip,
                    placeholder: c.placeholder,
                    opt_type: c.opt_type,
                    options_list: c.options_list
                  };
                  cList.push(cObj);
                });
                console.log(qObj.tooltip);
                await apiCall(`admin/updateQuestion/${qObj.QID}`, "PUT", {
                  question: qObj.qQuestion,
                  choices: cList,
                  tooltip: qObj.tooltip,
                });
              });
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const createAssmtGrp = async (assmtGrp, assmtList, deleteOldAssmt) => {
    try {
      assmtGrp["AID"] = v4();
      assmtGrp["type"] = "group";
      let assmtDone = await apiCall("admin/createAssmt", "POST", assmtGrp);
      if (assmtDone) {
        await assmtList.forEach(async (assmtId, aSeq) => {
          await apiCall(`admin/createAssmtGrp/${assmtGrp.AID}`, "POST", {
            AID: assmtId,
            aSeq: aSeq,
          });
        });

        if (deleteOldAssmt)
          apiCall(`admin/deleteAssmt/${assmtGrpID}`, "DELETE");

        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <EditAssmtContext.Provider
      value={{
        assmtID,
        setAssmtID,
        assmtObj,
        setAssmtObj,
        toast,
        setToast,
        message,
        setMessage,
        status,
        setStatus,
        sectionList,
        setSectionList,
        handleSectionAdd,
        handleSectionRemove,
        handleSectionChange,
        handleSectionList,
        createAssmt,
        createAssmtGrp,
        assmtGrpID,
        setAssmtGrpID,
      }}
    >
      {children}
    </EditAssmtContext.Provider>
  );
};

export default EditAssmtContextProvider;
